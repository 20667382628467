// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SWITCH */

.ant-switch {
  .ant-switch-inner {
    span {
      color: transparent;
    }
  }
}
.ant-switch-checked {
  background-color: $fairx-green;
}
