// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN DROPDOWN */

.ant-dropdown-menu {
  border: 1px solid #e9e9e9;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: rem(8) rem(20);
  color: $text;

  > a {
    padding: rem(8) rem(20);
    margin: rem(-8) rem(-20);
    color: $text;

    &:hover {
      color: $primary;
    }

    &.btn {
      margin: rem(-8) 0;
    }
  }
}

.ant-dropdown-menu-item-group-list {
  list-style: none;
  padding: 0;
}

.ant-dropdown-menu-item-group-title {
  font-weight: bold;
  padding: rem(12) rem(20);
  color: $text;
}
