@import 'assets/styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* BADGE */

.ag-theme-balham {
  .ag-cell.badge {
    padding: 0;
    font-weight: 500;
  }
}
