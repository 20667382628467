// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN FORM */

.ant-radio-wrapper,
.ant-checkbox-wrapper {
  color: $text;
}

.ant-form-item-label > label {
  color: $text;
}

.ant-form-item-label {
  white-space: normal;
}

.ant-picker-input > input {
  padding: 6px;
}
.ant-picker-large {
  padding: 5px 13px;
}
