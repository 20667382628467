// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN DATEPICKER */
.ant-picker-cell-selected,
.ant-picker-cell-range-start,
.ant-picker-cell-range-end {
  .ant-picker-cell-inner {
    background: $fairx-green !important;
  }
}

.ant-picker-header-prev-btn,
.ant-picker-header-super-prev-btn,
.ant-picker-header-next-btn,
.ant-picker-header-super-next-btn {
  color: $fairx-green !important;
}

.ant-picker-cell-in-range-hover,
.ant-picker-cell-range-hover-end,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: lighten($fairx-green, 40%) !important;
}

.ant-picker-today-btn {
  color: $fairx-green;
}
