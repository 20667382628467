@import 'assets/styles/mixins.scss';

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.dev {
    color: $fairx-cobalt;
  }

  &.frontend {
    color: $pink;
  }

  &.alpaca {
    color: $fairx-navy;
  }

  &.buffalo {
    color: $fairx-green;
  }

  &.capybara {
    color: $gray-6;
  }

  &.sentinel-pr {
    color: $gray-5;
  }

  &.sentinel-merge {
    color: $gray-3;
  }

  &.prod,
  &.prod-dr {
    justify-content: flex-end;
    color: $coinbase-blue;
  }

  &.int {
    color: $warning;
  }

  &.staging {
    color: $purple;
  }

  &.qa {
    color: $info;
  }

  .topbar__env {
    font-size: large;
    font-weight: 900;
    text-transform: uppercase;
  }

  .usermenu {
    color: $black;
    background-color: $white;
    padding-right: 10px;
  }
}
