.ant-table-row-expand-icon::before {
  top: 8px;
}
.ant-table-row-expand-icon::after {
  left: 8px;
}

.ant-table-filter-trigger:hover {
  color: lighten($fairx-green, 40%);
}

.ant-table-filter-trigger.active > * {
  color: revert;
  background-color: $fairx-green;
  border-radius: 50%;
  padding: 3px;
}

.ant-empty-img-simple-g {
  stroke: $fairx-cobalt;
}
