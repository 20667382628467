@import 'assets/styles/mixins';

.container {
  display: flex;
  flex-wrap: wrap;
  min-width: 80vw; // Add a little space for form to expand in order not to overlap fields with labels
}

.wrap {
  flex: 1 1 33.33%;
  max-width: 33%; // Otherwise last input added shows wider and unaligned
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 7px 11px 12px 11px; // Same padding than input boxes otherwise it looks smaller
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.ant-picker-large .ant-picker-input > input {
  font-size: 14px;
  padding: 14px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px;
  border: 0.75px solid $cds-input-border;
  padding: 12px;
}

.ant-picker {
  border-radius: 10px;
  border: 0.75px solid $cds-input-border;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 2px solid $border-focused;
  box-shadow: none;
}

.ant-picker-focused {
  border: 2px solid $border-focused;
  box-shadow: none;
}

.wrap .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  margin: 1px;
}

body .ant-picker-large {
  padding: 4px 13px;
}
