// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN PAGINATION */

.ant-pagination-prev:not(.ant-pagination-disabled),
.ant-pagination-next:not(.ant-pagination-disabled),
.ant-pagination-item:hover,
.ant-pagination-item-active {
  border: 1px solid $fairx-green;
}

.ant-pagination-item-active {
  background-color: $fairx-green;
  a {
    color: $white !important;
  }
}
