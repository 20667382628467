// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN CHECKBOX */

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $white;
  border-color: $fairx-green;
  &::after {
    border-color: $fairx-green;
  }
}
